import request from 'utils/request';
const api = {
  FINANCE_AFFILIATE_LIST: '/api/finance/affiliate/list',
  FINANCE_AFFILIATE_DOWNLOAD: '/api/finance/affiliate/download',
  FINANCE_AFFILIATE_ADD: '/api/finance/affiliate/save',
  FINANCE_AFFILIATE_CREATELASTMONTH: '/api/finance/affiliate/createLastMonth',
  FINANCE_AFFILIATE_UPDATE: '/api/finance/affiliate/update',
  FINANCE_AFFILIATE_BD_LIST: '/api/finance/affiliate/bd/list',
  FINANCE_AFFILIATE_TO_UPDATE: '/api/finance/affiliate/to/update',
  FINANCE_AFFILIATE_UPDATE_BATCH: '/api/finance/affiliate/updateBatch',
  FINANCE_AFFILIATE_UPDATE_RATE_BATCH: '/api/finance/affiliate/updateConfirmRatioBatch',
  FINANCE_AFFILIATE_TO_UPDATE_BATCH: '/api/finance/affiliate/to/updateTOBatch',

  APPROVAL_TODOLIST: '/api/finance/affiliate/listTODO',
  APPROVAL_AGREE: '/api/finance/affiliate/agreeApproval',
  APPROVAL_REJECT: '/api/finance/affiliate/rejectApproval',
  APPROVAL_AGREE_BATCH: '/api/finance/affiliate/agreeApprovalBatch',
  APPROVAL_REJECT_BATCH: '/api/finance/affiliate/rejectApprovalBatch',
  APPROVAL_DETAIL: '/api/finance/affiliate/searchApproval',
  APPROVAL_DONELIST: '/api/finance/affiliate/searchDoneList',
  APPROVAL_APPLYLIST: '/api/finance/affiliate/listApproval',
  APPROVAL_CCLIST: '/api/finance/affiliate/listCCApproval',

  APPROVAL_APPLY: 'api/finance/affiliate/saveApproval',
};

/**
 * 获取 运营对账流水（分页）
 * @param params
 * @returns {*}
 */
export function fetchPage(params) {
  return request({
    url: api.FINANCE_AFFILIATE_LIST,
    method: 'get',
    params,
  });
}

export function fetchBDPage(params) {
  return request({
    url: api.FINANCE_AFFILIATE_BD_LIST,
    method: 'get',
    params,
  });
}

export function save(data) {
  return request({
    url: api.FINANCE_AFFILIATE_ADD,
    method: 'post',
    data,
  });
}

export function createLastMonth(data) {
  return request({
    url: api.FINANCE_AFFILIATE_CREATELASTMONTH,
    method: 'post',
    data,
  });
}

export function edit(data) {
  return request({
    url: api.FINANCE_AFFILIATE_UPDATE,
    method: 'post',
    data,
  });
}

export function editTO(data) {
  return request({
    url: api.FINANCE_AFFILIATE_TO_UPDATE,
    method: 'post',
    data,
  });
}

export function confirmRatioBatch(data) {
  return request({
    url: api.FINANCE_AFFILIATE_UPDATE_RATE_BATCH,
    method: 'post',
    data,
  });
}

export function editBatch(data) {
  return request({
    url: api.FINANCE_AFFILIATE_UPDATE_BATCH,
    method: 'post',
    data,
  });
}

export function editTOBatch(data) {
  return request({
    url: api.FINANCE_AFFILIATE_TO_UPDATE_BATCH,
    method: 'post',
    data,
  });
}

export function download(params) {
  return request({
    url: api.FINANCE_AFFILIATE_DOWNLOAD,
    method: 'get',
    params,
  });
}

export function todoList(params) {
  return request({
    url: api.APPROVAL_TODOLIST,
    method: 'get',
    params,
  });
}
export function doneList(params) {
  return request({
    url: api.APPROVAL_DONELIST,
    method: 'get',
    params,
  });
}

export function agreeApproval(data) {
  return request({
    url: api.APPROVAL_AGREE,
    method: 'post',
    data,
  });
}

export function rejectApproval(data) {
  return request({
    url: api.APPROVAL_REJECT,
    method: 'post',
    data,
  });
}

export function agreeApprovalBatch(data) {
  return request({
    url: api.APPROVAL_AGREE_BATCH,
    method: 'post',
    data,
  });
}

export function rejectApprovalBatch(data) {
  return request({
    url: api.APPROVAL_REJECT_BATCH,
    method: 'post',
    data,
  });
}

export function getApprovalDetail(params) {
  return request({
    url: api.APPROVAL_DETAIL,
    method: 'get',
    params,
  });
}

export function applyList(params) {
  return request({
    url: api.APPROVAL_APPLYLIST,
    method: 'get',
    params,
  });
}
export function ccList(params) {
  return request({
    url: api.APPROVAL_CCLIST,
    method: 'get',
    params,
  });
}

export function applyApproval(data) {
  return request({
    url: api.APPROVAL_APPLY,
    method: 'post',
    data,
  });
}
