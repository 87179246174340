import request from 'utils/request';
const api = {
  BUSSINESS_LIST: '/api/finance/bussiness/list',
  BUSSINESS_UPDATE: '/api/finance/bussiness/update',
  BUSSINESS_CONFIRM: '/api/finance/bussiness/confirm',
  BUSSINESS_CONFIRM_BATCH: '/api/finance/bussiness/confirmBatch',
  UPDATE_PENDING_BATCH: '/api/finance/bussiness/updatePendingBatch',
  confirm2AdvAPI: '/api/finance/confirm2Adv',
  UPDATE_AWAIT: '/api/finance/bussiness/updateAwait',
  UPDATE_PENDING: '/api/finance/bussiness/updatePending',
  combine2AdvAPI: '/api/finance/combineAwait2Adv',

  APPROVAL_APPLY: 'api/finance/saveApproval',
  STATUS_APPROVAL_APPLY: 'api/finance/bdStatus/saveApproval',
  APPROVAL_REASON: 'api/finance/selectRensonMap',
  BUSSINESS_DOWNLOAD: '/api/finance/bussiness/download',
  SEARCH_PROGRESS: '/api/finance/bussiness/searchProgress',
  APPROVAL_ATTACHMENT: '/api/finance/approval/attachment',
  SOURCE_ATTACHMENT: '/api/finance/approval/source/attachment',

  compare_prices_list: '/api/finance/searchComparePrices',
};

/**
 * 获取 运营对账流水（分页）
 * @param params
 * @returns {*}
 */
export function fetchPage(params) {
  return request({
    url: api.BUSSINESS_LIST,
    method: 'get',
    params,
  });
}

export function searchProgress(params) {
  return request({
    url: api.SEARCH_PROGRESS,
    method: 'get',
    params,
  });
}

export function save(data) {
  return request({
    url: api.FINANCE_CONTRACT_ADD,
    method: 'post',
    data,
  });
}

export function remove(id) {
  return request({
    url: api.FINANCE_CONTRACT_REMOVE + '?id=' + id,
    method: 'delete',
    id,
  });
}

export function edit(data) {
  return request({
    url: api.BUSSINESS_UPDATE,
    method: 'post',
    data,
  });
}

export function confirm(data) {
  return request({
    url: api.BUSSINESS_CONFIRM,
    method: 'post',
    data,
  });
}

export function confirmBatch(data) {
  return request({
    url: api.BUSSINESS_CONFIRM_BATCH,
    method: 'post',
    data,
  });
}

export function updateAwait(data) {
  return request({
    url: api.UPDATE_AWAIT,
    method: 'post',
    data,
  });
}

export function updatePending(data) {
  return request({
    url: api.UPDATE_PENDING,
    method: 'post',
    data,
  });
}

export function updatePendingBatch(data) {
  return request({
    url: api.UPDATE_PENDING_BATCH,
    method: 'post',
    data,
  });
}

export function confirm2Adv(data) {
  return request({
    url: api.confirm2AdvAPI,
    method: 'post',
    data,
  });
}

export function combine2Adv(data) {
  return request({
    url: api.combine2AdvAPI,
    method: 'post',
    data,
  });
}

export function selectDetail(params) {
  return request({
    url: api.FINANCE_CONTRACT_SELECTBYID,
    method: 'get',
    params,
  });
}

export function applyApproval(data) {
  return request({
    url: api.APPROVAL_APPLY,
    method: 'post',
    data,
  });
}

export function applyApprovalReasons() {
  return request({
    url: api.APPROVAL_REASON,
    method: 'get',
  });
}

export function download(params) {
  return request({
    url: api.BUSSINESS_DOWNLOAD,
    method: 'get',
    params,
  });
}

export function approvalAttachment(params) {
  return request({
    url: api.APPROVAL_ATTACHMENT,
    method: 'get',
    params,
  });
}

export function sourceAttachment(params) {
  return request({
    url: api.SOURCE_ATTACHMENT,
    method: 'get',
    params,
  });
}

/**
 * 获取 运营对账流水（分页）
 * @param params
 * @returns {*}
 */
export function fetchComparePricesPage(params) {
  return request({
    url: api.compare_prices_list,
    method: 'get',
    params,
  });
}

export function statusApproval(data) {
  return request({
    url: api.STATUS_APPROVAL_APPLY,
    method: 'post',
    data,
  });
}
