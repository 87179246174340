import {
  fetchPage,
  editTO,
  createLastMonth,
  applyApproval,
  editTOBatch,
} from '@/api/finance/financeAffiliate';
import { mapState, mapActions } from 'vuex';
import Pagination from '@/components/pagination';
import GroupSelect from '@/components/GroupSelect';
import { selectProds } from 'api/product/overall';
import { getPidsList } from 'api/product/rankSearch';
import { filterObject } from 'utils/utils';
import qs from 'querystring';
import { approvalAttachment } from '@/api/finance/bussinessProcess';

export default {
  components: {
    Pagination,
    GroupSelect,
  },
  data() {
    return {
      filterForm: {
        page: 1,
        pageSize: 20,
        dateRange: [],
        showOffer: true,
      },
      total: 0,
      postData: {},
      list: [],
      loading: {
        list: false,
        submitBtn: false,
      },
      mmpRevenue: 0,
      affiliateFinance: {
        id: 0,
        confirm: 0,
        confirmRatio: 0,
        note: '',
        secondConfirmRatio: null,
      },
      dialog: {
        visible: false,
        visibleAttachments: false,
        title: '编辑',
        type: '',
      },
      applyApproval: {
        visible: false,
        submit: false,
        reasonOptions: [
          { label: 'PA', value: 'pa' },
          { label: 'Over Cap', value: 'overcap' },
          { label: 'CRM Validation', value: 'crm_validation' },
          { label: 'Other', value: 'other' },
        ],
      },
      applyApprovalForm: {
        financeAffiliateId: '',
        offerId: '',
        offerName: '',
        totalAmount: 0.0,
        deductionAmount: 0.0,
        coverAmount: 0.0,
        payableAmount: 0.0,
        deduction: 0.0,
        cover: 0.0,
        note1: '',
        note2: '',
        sourceId: 0,
        sourceName: '',
        affiliateId: '',
        affiliateName: '',
        prod: '',
        financeCycle: '',
      },
      sumInfo: {
        offerCount: 0,
        offerConfirmCount: 0,
        offerConfirmRate: 0,
        affiliateCount: 0,
        affiliateConfirmCount: 0,
        affiliateConfirmRate: 0,
      },
      applyRules: {
        appName: [{ required: true }],
        deductionAmount: [{ required: true, type: 'number', trigger: 'blur' }],
        totalAmount: [{ required: true, type: 'number', trigger: 'blur' }],
        payableAmount: [{ required: true, type: 'number', trigger: 'blur' }],
        deduction: [{ required: true, type: 'number' }],
        note1: [{ required: true, min: 1, max: 255, message: '长度在 1 到 255 个字符' }],
        reason: [{ required: true }],
      },
      multipleSelection: [],
      copyArr: [],
      rules: {
        confirmRatio: [
          { required: true, type: 'number', message: 'confirmRatio不能为空', trigger: 'blur' },
        ],
      },
      statusList: [
        {
          label: 'created',
          value: '0',
        },
        {
          label: 'BD confirm',
          value: '1',
        },
        {
          label: 'TO confirm',
          value: '2',
        },
      ],
      prodList: [],
      pidList: [],
      financeAttachments: [],
      businessTypes: [
        {
          label: 'overseas',
          value: 'overseas',
        },
        {
          label: 'domestic',
          value: 'domestic',
        },
      ],
      cpis: [
        {
          label: 'cpi',
          value: 'cpi',
        },
        {
          label: 'cpa',
          value: 'cpa',
        },
      ],
    };
  },
  mounted() {
    this.getDefaultTime();
    this.getList();
    this.getSourceIdsList();
    this.applyApprovalFormReset = JSON.stringify(this.applyApprovalForm);
    //获取Affiliate
    this.getAffiliateList();
    this.prodsList();
    this.getPids();
  },
  methods: {
    // get Source
    ...mapActions('source', ['getSourceIdsList']),
    // get Affiliate
    ...mapActions('affiliate', ['getAffiliateList']),
    getDefaultTime() {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() - 2); // 设置日期为上上月
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1;
      const formattedMonth = month < 10 ? `0${month}` : month;
      let ddr = `${year}-${formattedMonth}`;
      this.filterForm.dateRange = [ddr, ddr]; // 设置默认起始日期
    },
    // 获取列表
    // 获取prods
    prodsList() {
      selectProds().then((res) => {
        this.prodList = res.result;
      });
    },
    getList(page) {
      if (page) {
        this.filterForm.page = page;
      }
      let param = { ...this.filterForm };
      if (this.filterForm.sourceIdList) {
        param.sourceIdList = this.filterForm.sourceIdList.toString();
      }
      if (this.filterForm.affiliateIds) {
        param.affiliateIds = this.filterForm.affiliateIds.toString();
      }
      if (this.filterForm.prods) {
        param.prods = this.filterForm.prods.toString();
      }
      if (this.filterForm.pids) {
        param.pids = this.filterForm.pids.toString();
      }
      if (this.filterForm.status) {
        param.statusList = this.filterForm.status.toString();
        delete param.status;
      }
      let rangeTime = this.filterForm.dateRange;
      if (Array.isArray(rangeTime) && rangeTime.length > 0) {
        param.startTime = this.filterForm.dateRange[0];
        param.endTime = this.filterForm.dateRange[1];
        // 删除多余的属性
        delete param.dateRange;
      }
      this.loading.list = true;
      fetchPage(param)
        .then((res) => {
          if (res.code === 200) {
            this.sumInfo = res.result.pop();
            this.list = res.result;
            this.total = res.total;
          } else {
            this.$message.error(res.message);
          }
          this.loading.list = false;
        })
        .catch((e) => {
          this.loading.list = false;
          this.$message.error(e.message);
        });
    },
    // 打开弹窗
    openDialog(row) {
      this.affiliateFinance.confirm = row.confirm;
      this.affiliateFinance.confirmRatio = Number(parseFloat(row.confirmRatio * 100).toFixed(2));
      if (row.secondConfirmRatio) {
        this.affiliateFinance.secondConfirmRatio = Number(
          parseFloat(row.secondConfirmRatio * 100).toFixed(2)
        );
      }
      this.affiliateFinance.note = row.note;
      this.affiliateFinance.prod = row.prod;
      this.affiliateFinance.sourceId = row.sourceId;
      this.affiliateFinance.affiliateId = row.affiliateId;
      this.affiliateFinance.financeCycle = row.financeCycle;
      this.affiliateFinance.payout = row.payout;
      this.dialog.visible = true;
    },
    openFile(row) {
      window.open(row.deductionFileUrl, '_blank');
    },
    handleApplyReasonChange() {
      this.$refs['applyApprovalForm'].clearValidate();
    },
    approvalFormReset() {
      this.applyApprovalForm = JSON.parse(this.applyApprovalFormReset);
    },

    // 打开弹窗
    openAffDialog(row) {
      let param = {
        prod: row.prod,
        sourceId: row.sourceId,
        financeCycle: row.financeCycle,
        status: 3,
      };
      approvalAttachment(param).then((res) => {
        this.financeAttachments = res.result;
      });
      this.dialog.visibleAttachments = true;
    },

    toApplyApproval(row) {
      this.applyApprovalForm.prod = row.prod;
      this.applyApprovalForm.payout = parseFloat(row.payout);
      this.applyApprovalForm.confirm = parseFloat(row.confirm);
      this.applyApprovalForm.confirmRatio = parseFloat(row.confirmRatio);
      this.applyApprovalForm.financeAffiliateId = row.id;
      this.applyApprovalForm.sourceId = row.sourceId;
      this.applyApprovalForm.sourceName = row.sourceName;
      this.applyApprovalForm.affiliateId = row.affiliateId;
      this.applyApprovalForm.affiliateName = row.affiliateName;
      this.applyApprovalForm.offerId = row.offerId;
      this.applyApprovalForm.offerName = row.offerName;
      this.applyApprovalForm.financeCycle = row.financeCycle;
      this.applyApproval.visible = true;
      this.applyApproval.submit = false;
    },
    applyBeforeClose(done) {
      if (!this.applyApproval.submit) {
        this.$confirm('form data will lost before submit , go on ?')
          .then(() => {
            this.approvalFormReset();
            this.handleApplyReasonChange();
            done();
          })
          .catch(() => {});
      } else {
        done();
      }
    },
    applyApprovalSubmit() {
      this.$refs['applyApprovalForm'].validate((valid) => {
        if (valid) {
          const formData = JSON.parse(JSON.stringify(this.applyApprovalForm));
          let data = {};
          data.prod = formData.prod;
          data.payout = formData.payout;
          data.confirm = formData.confirm;
          data.deduction = formData.deductionAmount;
          data.financeAffiliateId = formData.financeAffiliateId;
          data.sourceId = formData.sourceId;
          data.sourceName = formData.sourceName;
          data.affiliateId = formData.affiliateId;
          data.affiliateName = formData.affiliateName;
          data.offerId = formData.offerId;
          data.offerName = formData.offerName;
          data.cover = formData.cover;
          data.payout = formData.payout;
          data.confirm = formData.confirm;
          data.confirmRatio = formData.confirmRatio;
          data.financeCycle = formData.financeCycle;
          data.note = [formData.note1];
          if (formData.note2) {
            data.note.push(formData.note2);
          }
          applyApproval(data).then((resp) => {
            if (resp.code == 200) {
              this.approvalFormReset();
              this.applyApproval.visible = false;
              this.applyApproval.submit = true;
              this.$message({
                message: 'Save Success',
                type: 'success',
              });
              this.getList();
            } else {
              this.$message.error(resp.message);
            }
          });
        } else {
          console.log(valid);
        }
      });
    },

    handleSizeChange(size) {
      this.filterForm.pageSize = size;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(currentPage) {
      this.filterForm.page = currentPage;
      this.getList();
    },
    update() {
      this.$refs['affiliateFinanceForm'].validate((valid) => {
        if (valid) {
          var affForm = JSON.parse(JSON.stringify(this.affiliateFinance));
          affForm.confirmRatio = this.affiliateFinance.confirmRatio / 100;
          if (this.affiliateFinance.secondConfirmRatio) {
            affForm.secondConfirmRatio = this.affiliateFinance.secondConfirmRatio / 100;
          }
          editTO({ ...affForm }).then((response) => {
            if (response.success === true) {
              this.$message({
                message: 'Update Success',
                type: 'success',
              });
              this.getList();
              this.dialog.visible = false;
              this.affiliateFinance = {
                id: 0,
                confirm: 0,
                confirmRatio: 0,
                note: '',
                secondConfirmRatio: null,
              };
            } else {
              this.$message.error('Update Error:' + response.message);
            }
          });
        } else {
          return false;
        }
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.copyArr = [];
      for (let i = 0; i < this.multipleSelection.length; i++) {
        let idFinance = this.multipleSelection[i].id;
        if (idFinance) {
          this.copyArr.push(this.multipleSelection[i].id);
        }
      }
      console.log(this.copyArr.toString());
    },
    updateBatch() {
      if (this.copyArr.length === 0) {
        this.$message.error('Please select the item to be confirm');
        return;
      }
      this.loading.submitBtn = true;
      this.$confirm('此操作将批量 confirm?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(({ value }) => {
          let targetList = this.copyArr.slice();
          editTOBatch({ ids: targetList, noteMsg: value })
            .then((resp) => {
              if (resp.success === true) {
                this.$message({
                  message: 'Update Success',
                  type: 'success',
                });
                this.loading.submitBtn = false;
                this.getList();
              } else {
                this.loading.submitBtn = false;
                this.$message.error('Update  Error:' + resp.message);
              }
            })
            .catch((error) => {
              this.loading.submitBtn = false;
              this.$message({
                type: 'info',
                message: error.message,
              });
            });
        })
        .catch((error) => {
          this.loading.submitBtn = false;
          this.$message({
            type: 'info',
            message: error.message,
          });
        });
    },
    // 批量新增上月数据
    insertBatch() {
      this.$confirm('此操作将批量生成上月数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          this.loading.submitBtn = true;
          createLastMonth({})
            .then((res) => {
              if (res.success === true) {
                this.$message.success('新增成功！');
                this.getList();
                this.loading.submitBtn = false;
              } else {
                this.$message.error(res.message);
                this.loading.submitBtn = false;
              }
            })
            .catch((error) => {
              this.loading.submitBtn = false;
              this.$message({
                type: 'info',
                message: error.message,
              });
            });
        })
        .catch(() => {
          this.loading.submitBtn = false;
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
    // 下载excel表格
    downloadFie() {
      this.$confirm('最多下载10万条数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          let param = { ...this.filterForm };
          if (this.filterForm.sourceIdList) {
            param.sourceIdList = this.filterForm.sourceIdList.toString();
          }
          if (this.filterForm.affiliateIds) {
            param.affiliateIds = this.filterForm.affiliateIds.toString();
          }
          if (this.filterForm.prods) {
            param.prods = this.filterForm.prods.toString();
          }
          if (this.filterForm.pids) {
            param.pids = this.filterForm.pids.toString();
          }
          if (this.filterForm.status) {
            param.statusList = this.filterForm.status.toString();
            delete param.status;
          }
          let rangeTime = this.filterForm.dateRange;
          if (Array.isArray(rangeTime) && rangeTime.length > 0) {
            param.startTime = this.filterForm.dateRange[0];
            param.endTime = this.filterForm.dateRange[1];
            // 删除多余的属性
            delete param.dateRange;
          }

          param = filterObject(param);
          let strParam = qs.stringify(param);
          let url = '/api/finance/affiliate/download?' + strParam;
          console.log(url);
          window.open(url, '_blank');
        })
        .catch((error) => {
          this.$message({
            type: 'info',
            message: error.message,
          });
        });
    },
    // 排序监听
    sortChange(column) {
      if (column.prop !== null && column.order !== null) {
        if (column.prop === 'eventCount') {
          this.filterForm.sorting = 'event_count';
        } else {
          this.filterForm.sorting = column.prop;
        }
        this.filterForm.sortType = column.order === 'ascending' ? 'asc' : 'desc';
      } else {
        this.filterForm.sorting = 'id';
        this.filterForm.sortType = 'desc';
      }
      this.getList();
    },
    formatValue(val) {
      if (val === '0') {
        return 'create';
      } else if (val === '1') {
        return 'BD confirm';
      } else if (val === '2') {
        return 'TO confirm';
      } else if (val === '3') {
        return 'Approval';
      } else {
        return val;
      }
    },
    idDisableFun(val) {
      return !(val === '0');
    },
    computeConfirmNum() {
      let num = ((this.affiliateFinance.confirmRatio / 100) * this.affiliateFinance.payout).toFixed(
        3
      );
      this.affiliateFinance.confirm = num;
    },
    getPids() {
      getPidsList().then((res) => {
        this.pidList = res.result;
      });
    },
    computeRatio(row) {
      if (isNaN(row.confirmAf) || isNaN(row.confirmBd)) {
        return 0;
      }
      if (row.confirmAf === 0) {
        return 100;
      } else {
        return ((row.confirmBd / row.confirmAf) * 100).toFixed(3);
      }
    },
  },
  computed: {
    ...mapState('source', {
      sourcesList: (state) => state.sourcesList,
      sourceLoading: (state) => state.sourceLoading,
    }),
    ...mapState('affiliate', {
      affiliatesGroupList: (state) => state.affiliatesGroupList,
      affiliateLoading: (state) => state.affiliateLoading,
    }),
  },
};
